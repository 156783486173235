// CreationProcess.tsx

import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { colors } from '@/styles/theme/colors';
import { H2, H5 } from '@/components/Headings';
import ProcessDisplay from './ProcessDisplay';

const CreationProcess = () => {
  const { t } = useTranslation('main');

  return (
    <Stack
      w='full'
      align={'center'}
      spacing={8}
      py={{ base: 16, md: 24 }}
      justify={'start'}
    >
      <Stack
        w='full'
        align={'center'}
        textAlign={'center'}
        maxW={'2xl'}
        spacing={4}
        px={{ base: 12, lg: 0 }}
      >
        <H5 color={colors.diment.lightBlue}>
          {t('home.creation-process.desc')}
        </H5>
        <H2 color={colors.diment.darkBlue}>
          {t('home.creation-process.heading')}
        </H2>
      </Stack>
      <ProcessDisplay />
    </Stack>
  );
};

export default CreationProcess;
