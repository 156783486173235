// OurNetworkGrid.tsx

import { AspectRatio, Box, Flex, Link } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { outerLinks } from '@/utils/constants/outer-links';
import { TextSmall } from '@/components/Texts';
import DynamicOurNetworkImage from './DynamicOurNetworkImage';

const ourNetwork: Record<string, string[]> = {
  auditors: ['certic'],
  shariah: ['gifs'],
  sustainability: ['ifsg'],
  certifications: ['gia', 'hrd', 'igi'],
  origins: ['kimberley'],
  memberships: ['antwerpsche', 'wfdb'],
  storage: ['diavault'],
  suppliers: ['quatrec'],
  insurances: ['drie', 'aig'],
  distributor: ['dimentva'],
};

interface OurNetworkGridProps {
  bgColor?: string;
}

const OurNetworkGrid: React.FC<OurNetworkGridProps> = ({
  bgColor = 'rgba(255, 255, 255, 0.4)',
}) => {
  const { t } = useTranslation('main');

  return (
    <Flex
      wrap='wrap'
      justify='center'
      w='full'
      maxW='8xl'
      gap={{ base: 2, md: 4 }}
    >
      {Object.entries(ourNetwork).flatMap(([key, logos]) =>
        logos.map((logo) => (
          <Link
            key={logo}
            href={(outerLinks.network as any)[logo]}
            target='_blank'
            flex='0 0 13%'
            minW='180px'
          >
            <AspectRatio ratio={1} h='full'>
              <Flex
                direction='column'
                align='center'
                bg={bgColor}
                transition='transform 0.3s, box-shadow 0.3s'
                _hover={{
                  transform: 'translateY(-4px)',
                  boxShadow: 'lg',
                }}
                p={{ base: 4, md: 8 }}
                position='relative'
              >
                <DynamicOurNetworkImage member={logo} />
                <TextSmall
                  fontSize={{ base: '10px', sm: '12px' }}
                  fontWeight={400}
                  position='absolute'
                  bottom={2}
                  w='full'
                  textAlign='center'
                  px={1}
                >
                  {t(`home.ournetwork.${key}`)}
                </TextSmall>
              </Flex>
            </AspectRatio>
          </Link>
        )),
      )}
    </Flex>
  );
};

export default OurNetworkGrid;
