import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BuyTokenForm, CommonStepProps } from './types';
import { useTranslation } from 'next-i18next';
import { HiChevronDown } from 'react-icons/hi2';
import Image from 'next/image';

const options = [
  // { key: 'btc', symbol: 'BTC', name: 'Bitcoin' },
  {
    key: 'eth',
    symbol: 'ETH',
    name: 'Ethereum',
    imageSrc: '/images/og.png',
  },
  { key: 'usdt', symbol: 'USDT', name: 'TetherUS' },
  { key: 'usdc', symbol: 'USDC', name: 'USD Coin' },
  { key: 'tusd', symbol: 'TUSD', name: 'TrueUSD' },
  { key: 'dai', symbol: 'DAI', name: 'Dai' },
];

const SelectCryptocurrencyStep = ({ goNextStep }: CommonStepProps) => {
  const formMethods = useFormContext<BuyTokenForm>();
  const selectedCrypto = options.find(
    (option) => option.key === formMethods.watch('cryptocurrency'),
  );
  const error = formMethods.formState.errors.cryptocurrency;
  const { t } = useTranslation('main');
  console.log(formMethods.watch());

  return (
    <Stack spacing={4}>
      <Text textAlign={'center'} whiteSpace={'pre-line'}>
        {t('common.buy-token.cryptocurrency-description')}
      </Text>
      <FormControl isInvalid={!!error}>
        <Menu>
          <MenuButton
            w={'full'}
            as={Button}
            textAlign={'start'}
            rounded={'md'}
            colorScheme='gray'
            rightIcon={<HiChevronDown />}
          >
            {selectedCrypto ? (
              <Flex gap={2} align={'center'}>
                <Image
                  src={`/images/tokens/${selectedCrypto.symbol.toLowerCase()}.png`}
                  width={20}
                  height={20}
                  alt={selectedCrypto.symbol}
                />
                <Flex align={'baseline'} gap={2}>
                  <Text>{selectedCrypto.symbol}</Text>
                  <Text fontSize={'xs'} color={'gray.500'}>
                    {selectedCrypto.name}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              t('common.buy-token.select-cryptocurrency')
            )}
          </MenuButton>
          <MenuList>
            {options?.map((option) => {
              if (option.key === selectedCrypto?.key)
                return <React.Fragment key={option.symbol} />;

              return (
                <MenuItem
                  onClick={() =>
                    formMethods.setValue('cryptocurrency', option.key)
                  }
                  key={option.symbol}
                >
                  <Flex gap={2} align={'center'}>
                    <Image
                      src={`/images/tokens/${option.symbol.toLowerCase()}.png`}
                      width={20}
                      height={20}
                      alt={option.symbol}
                    />
                    <Flex align={'baseline'} gap={2}>
                      <Text>{option.symbol}</Text>
                      <Text fontSize={'xs'} color={'gray.500'}>
                        {option.name}
                      </Text>
                    </Flex>
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>

        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>

      <Button
        variant={'outline'}
        onClick={async () => {
          (await formMethods.trigger('cryptocurrency')) && goNextStep();
        }}
      >
        {t('common.buy-token.confirm')}
      </Button>
    </Stack>
  );
};

export default SelectCryptocurrencyStep;
