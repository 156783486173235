// pages/index.tsx

import type { GetStaticProps } from 'next/types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import HomeContainer from '@/containers/HomeContainer';
import {
  META_DESCRIPTIONS,
  META_TITLES,
} from '@/utils/constants/meta-descriptions';
import { NextSeo } from 'next-seo';

const HomePage = () => {
  return (
    <>
      <NextSeo title={META_TITLES.home} description={META_DESCRIPTIONS.home} />
      <HomeContainer />
    </>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale || ctx.defaultLocale!, [
        'main',
      ])),
    },
  };
};

export default HomePage;
