import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Image,
  Tr,
  Icon,
  TableContainer,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BuyTokenForm, CommonStepProps } from './types';
import { useTranslation } from 'next-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import FaqFFModal from './FaqFFModal';
import Link from 'next/link';
import { HiExternalLink } from 'react-icons/hi';

const MIN_DD = 100;

const levels = [
  { value: 'iron', dimension: 4, min: '100 USD', percent: '6%' },
  { value: 'bronze', dimension: 4, min: '1.000 USD', percent: '8%' },
  { value: 'silver', dimension: 4, min: '10.000 USD', percent: '10%' },
  { value: 'gold', dimension: 4, min: '100.000 USD', percent: '12%' },
  { value: 'diamond', dimension: 4, min: '1.000.000 USD', percent: '14%' },
];

const EnterAmountStep = ({
  goNextStep,
  onCancel,
}: CommonStepProps & { onCancel: () => void }) => {
  const formMethods = useFormContext<BuyTokenForm>();
  const { t } = useTranslation('main');
  let error = formMethods.formState.errors.amount;

  const [amountError, showAmountError] = useState(false);

  const handleSubmit = async () => {
    await formMethods.trigger('amount');
    const values = formMethods.getValues();
    if (values.amount >= MIN_DD) {
      goNextStep();
    } else {
      showAmountError(true);
    }
  };

  return (
    <Stack spacing={4}>
      <Text textAlign={'center'} whiteSpace={'pre-line'}>
        {t('common.buy-token.amount-description')}
      </Text>
      <FormControl isInvalid={!!error}>
        <Stack w='full' align={'center'}>
          <Input
            textAlign={'center'}
            fontWeight={'semibold'}
            fontSize={'2xl'}
            maxW={'xs'}
            type='number'
            inputMode='numeric'
            {...formMethods.register('amount', { min: MIN_DD })}
            placeholder={t('common.buy-token.amount-placeholder')}
          />
          <Text hidden={!amountError} color={'red'} fontSize={'sm'}>
            Amount should bigger than {MIN_DD}
          </Text>
        </Stack>

        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
      <Card w={'fit-content'} alignSelf={'center'} bgColor='primary.50'>
        <CardBody p={2}>
          <Stack align={'center'}>
            <Flex gap={2} align={'center'}>
              <Icon
                as={FaInfoCircle}
                width={8}
                height={8}
                color={'primary.500'}
              />
              <Text fontWeight={'bold'}>1 DD = 1 USD</Text>
            </Flex>
          </Stack>
        </CardBody>
      </Card>

      <Box textAlign={'center'} fontSize={'sm'} fontWeight={'semibold'}>
        <Flex justify={'center'} align={'center'} gap={1} cursor={'pointer'}>
          <Link
            target='_blank'
            href={'https://app.diment.io/docs/information.pdf'}
          >
            <Text>{t('common.buy-token.project-info')}</Text>
          </Link>
          <Box as={HiExternalLink} color={'primary.500'} />
        </Flex>
      </Box>

      <ButtonGroup w={'full'} py={4}>
        <Button flex={1} variant={'ghost'} onClick={onCancel}>
          {t('common.buy-token.cancel')}
        </Button>
        <Button flex={1} onClick={handleSubmit}>
          {t('common.buy-token.next')}
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default EnterAmountStep;
