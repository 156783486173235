// AboutCards.tsx

import { Flex } from '@chakra-ui/react';
import Card from './Card';

const AboutCards: React.FC = () => {
  const cards = [
    {
      key: 'pre-backed',
      imageSrc: '/images/home/pre-backed.webp',
      variant: 'pre-backed' as const,
    },
    {
      key: 'pre-audit',
      imageSrc: '/images/home/pre-audit.webp',
      variant: 'pre-audit' as const,
    },
    {
      key: 'value-security',
      imageSrc: '/images/home/value-security.webp',
      variant: 'value-security' as const,
    },
  ];

  return (
    <Flex w={'full'} maxW={'8xl'} justify={'center'} wrap={'wrap'} gap={4}>
      {cards.map((card) => (
        <Card
          key={card.key}
          imageSrc={card.imageSrc}
          cardKey={card.key}
          variant={card.variant}
        />
      ))}
    </Flex>
  );
};

export default AboutCards;
