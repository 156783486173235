// components/HeroActions.tsx
import { Flex, Link } from '@chakra-ui/react';
import { FiArrowUpRight } from 'react-icons/fi';
import { useTranslation } from 'next-i18next';
import BaseButton from '@/components/BaseButton';
import { TextDefault } from '@/components/Texts';
import BuyToken from '@/components/BuyToken';

interface HeroActionsProps {
  outerLinks: { signUp: string };
  innerLinks: { whitepaperPdf: string };
  colors: any;
}

export const HeroActions = ({ innerLinks, colors }: HeroActionsProps) => {
  const { t } = useTranslation('main');

  return (
    <Flex
      align={'center'}
      justify={{ base: 'center', md: 'left' }}
      w='full'
      gap={3}
      wrap={'wrap'}
    >
      <BuyToken
        px={8}
        py={5}
        color={colors.diment.darkBlue}
        bgColor={colors.diment.lightBlue}
        fontWeight={900}
        fontSize={18}
        buttonName={t('home.hero.buy')}
      />
      <Link href={innerLinks.whitepaperPdf} target='_blank'>
        <BaseButton
          px={8}
          color={'white'}
          variant={'outline'}
          rightIcon={<FiArrowUpRight />}
          fontSize={'xl'}
        >
          <TextDefault>{t('home.hero.secondary-button')}</TextDefault>
        </BaseButton>
      </Link>
    </Flex>
  );
};
