import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BuyTokenForm } from './types';
import { useTranslation } from 'next-i18next';
import { outerLinks } from '@/utils/constants/outer-links';
import { useRouter } from 'next/router';

const SelectMethodStep = () => {
  const formMethods = useFormContext<BuyTokenForm>();
  const { t } = useTranslation('main');
  const router = useRouter();

  const handleSubmit = (method: BuyTokenForm['method']) => () => {
    let queryString = `?method=${method}`;

    Object.entries(formMethods.getValues()).forEach(([key, value]) => {
      if (!!value) queryString += `&${key}=${value}`;
    });

    router.push(outerLinks.buyToken + queryString);
  };

  return (
    <Stack spacing={4}>
      <Text textAlign={'center'} whiteSpace={'pre-line'}>
        {t('common.buy-token.account-description')}
      </Text>
      <Flex w={'full'} justify={'center'}>
        <Button onClick={handleSubmit('login')} w='full' mx={5}>
          {t('common.buy-token.account-login')}
        </Button>
      </Flex>

      <Flex gap={2} align={'center'} justify={'center'}>
        <Text>{t('common.buy-token.dont-have-account')}</Text>
        <Button
          onClick={handleSubmit('register')}
          variant={'ghost'}
          colorScheme={'primary'}
        >
          {t('common.buy-token.create-account')}
        </Button>
      </Flex>
    </Stack>
  );
};

export default SelectMethodStep;
