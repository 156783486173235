// HomeContainer/index.tsx

import BaseContainer from '@/components/BaseContainer';

import { Box } from '@chakra-ui/react';
import Reveal from './Reveal';
import OurNetwork from './OurNetwork/OurNetwork';

import HomeSimpleHero from './Hero/HomeSimpleHero';
import LearnMore from './LearnMore';
import CallToAction from './CallToAction';
import AboutDimentCardsSection from './AboutDimentCardsSection';
import CreationProcess from './CreationProcess/CreationProcess';
import GetApp from './GetApp';
import { colors } from '@/styles/theme/colors';
import PartnerTape from './PartnerTape';

type Component = {
  id: string;
  component: JSX.Element;
  bg: string;
  animate: boolean;
  container: boolean;
};

const components: Component[] = [
  {
    id: 'homesimplehero',
    component: <HomeSimpleHero />,
    bg: 'white',
    animate: false,
    container: false,
  },
  // {
  //   id: 'partnertape',
  //   component: <PartnerTape />,
  //   bg: 'white',
  //   animate: true,
  //   container: false,
  // },
  {
    id: 'learnmore',
    component: <LearnMore />,
    bg: 'white',
    animate: true,
    container: false,
  },
  {
    id: 'aboutdiment',
    component: <AboutDimentCardsSection />,
    bg: 'white',
    animate: true,
    container: false,
  },
  {
    id: 'creationprocess',
    component: <CreationProcess />,
    bg: colors.diment.lightGrey,
    animate: true,
    container: false,
  },
  {
    id: 'ournetwork',
    component: <OurNetwork />,
    bg: 'white',
    animate: true,
    container: false,
  },
  {
    id: 'calltoaction',
    component: <CallToAction />,
    bg: colors.gradients.darkBlue,
    animate: true,
    container: false,
  },
  {
    id: 'getapp',
    component: <GetApp />,
    bg: 'white',
    animate: true,
    container: false,
  },
];

const HomeContainer = () => {
  return (
    <>
      {components.map((item, index) => {
        return (
          <Box key={item.id} bg={item.bg} w='full' my={4}>
            {item.container ? (
              <BaseContainer>
                <Reveal animate={item.animate}>{item.component}</Reveal>
              </BaseContainer>
            ) : (
              <Reveal animate={item.animate}>{item.component}</Reveal>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default HomeContainer;
