// AboutDimentCardsSection.tsx

import { Stack } from '@chakra-ui/react';
import React from 'react';
import AboutCards from './AboutDimentCards/AboutCards';

const AboutDimentCardsSection = () => {
  return (
    <Stack w='full' align={'center'} px={{ base: 4, lg: 2 }}>
      <AboutCards />
    </Stack>
  );
};

export default AboutDimentCardsSection;
