export const useFeatureData = () => {
  return [
    {
      imageSrc: '/images/home/home-hero/diamond.svg',
      altText: 'Diamond',
      textKey: 'home.hero.diamond',
    },
    {
      imageSrc: '/images/home/home-hero/usd.svg',
      altText: 'USD',
      textKey: 'home.hero.usd',
    },
    {
      imageSrc: '/images/home/home-hero/pre.svg',
      altText: 'Pre',
      textKey: 'home.hero.pre',
    },
  ];
};
