import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BuyTokenForm, CommonStepProps } from './types';
import { useTranslation } from 'next-i18next';
import { HiChevronDown } from 'react-icons/hi2';
import Image from 'next/image';

const options = [
  {
    key: 'usd',
    symbol: 'USD',
    name: 'US Dollar',
  },
  {
    key: 'eur',
    symbol: 'EUR',
    name: 'Euro',
  },
  {
    key: 'aed',
    symbol: 'AED',
    name: 'UAE Dirham',
  },
  {
    key: 'chf',
    symbol: 'CHF',
    name: 'Swiss Franc',
  },
  { key: 'gbp', symbol: 'GBP', name: 'GB Pound', imageSrc: '/images/info.png' },
];

const SelectFiatStep = ({ goNextStep }: CommonStepProps) => {
  const formMethods = useFormContext<BuyTokenForm>();
  const selectedFiat = options.find(
    (option) => option.key === formMethods.watch('fiat'),
  );
  const error = formMethods.formState.errors.fiat;
  const { t } = useTranslation('main');

  return (
    <Stack spacing={4}>
      <Text textAlign={'center'} whiteSpace={'pre-line'}>
        {t('common.buy-token.fiat-description')}
      </Text>
      <FormControl isInvalid={!!error}>
        <Menu>
          <MenuButton
            w={'full'}
            as={Button}
            textAlign={'start'}
            rounded={'md'}
            colorScheme='gray'
            rightIcon={<HiChevronDown />}
          >
            {selectedFiat ? (
              <Flex gap={2} align={'center'}>
                <Image
                  src={`/images/fiat/${selectedFiat.symbol.toLowerCase()}.svg`}
                  width={20}
                  height={20}
                  alt={selectedFiat.symbol}
                />
                <Flex align={'baseline'} gap={2}>
                  <Text>{selectedFiat.symbol}</Text>
                  <Text fontSize={'xs'} color={'gray.500'}>
                    {selectedFiat.name}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              t('common.buy-token.select-fiat')
            )}
          </MenuButton>
          <MenuList>
            {options?.map((option) => {
              if (option.key === selectedFiat?.key)
                return <React.Fragment key={option.symbol} />;

              return (
                <MenuItem
                  onClick={() => formMethods.setValue('fiat', option.key)}
                  key={option.symbol}
                >
                  <Flex gap={2} align={'center'}>
                    <Image
                      src={`/images/fiat/${option.symbol.toLowerCase()}.svg`}
                      width={20}
                      height={20}
                      alt={option.symbol}
                    />
                    <Flex align={'baseline'} gap={2}>
                      <Text>{option.symbol}</Text>
                      <Text fontSize={'xs'} color={'gray.500'}>
                        {option.name}
                      </Text>
                    </Flex>
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>

        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>

      <Button
        variant={'outline'}
        onClick={async () => {
          (await formMethods.trigger('fiat')) && goNextStep();
        }}
      >
        {t('common.buy-token.confirm')}
      </Button>
    </Stack>
  );
};

export default SelectFiatStep;
