import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'next-i18next';
import VideoModal from '../../components/VideoModal';
import { H3 } from '@/components/Headings';
import { colors } from '@/styles/theme/colors';
import { TextDefault } from '@/components/Texts';
import ZoomableBackground from '@/components/ZoomableBackground';

const LearnMore = () => {
  const { t } = useTranslation('main');

  return (
    <ZoomableBackground
      bgImage={'/images/home/creating-value-bg.webp'}
      py={{ base: 20, md: 40 }}
      px={6}
    >
      <Stack
        zIndex={1}
        w={'full'}
        maxW={875}
        align={'center'}
        textAlign={'center'}
        spacing={6}
      >
        <H3
          w={'full'}
          maxW={{ base: '2xs', md: 'full' }}
          fontSize={{ base: 36, md: 45 }}
          color={colors.diment.darkBlue}
        >
          {t('home.learn-more.header')}
        </H3>
        <Box fontWeight={500} color={colors.diment.darkBlue}>
          <TextDefault>
            <Trans
              ns='main'
              i18nKey={'home.learn-more.content'}
              components={{ 1: <strong /> }}
            />
          </TextDefault>
        </Box>
        <VideoModal
          videoUrl={`/videos/what-is-diment-dollar.mp4`}
          btnColor={colors.diment.darkBlue}
        />
      </Stack>
    </ZoomableBackground>
  );
};

export default LearnMore;
