// HeroImage.tsx
import {
  Box,
  Stack,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import Image from 'next/image';

import bottomImg from '../../../assets/home/diment-coin-bottom-home.webp';
import topImg from '../../../assets/home/diment-coin-top-home.webp';

type HeroImageProps = {
  display: { base: string; md: string };
};

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(30px); }
  100% { transform: translateY(0); }
`;

export const HeroImage = ({ display }: HeroImageProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${floatAnimation} infinite 3s ease-in-out`;
  return (
    <Stack align='center' justify='center' display={display} spacing={0}>
      <Box
        w={'full'}
        maxW={{ base: '170px', sm: 'xs', md: '450px' }}
        animation={animation}
      >
        <Image
          src={topImg}
          alt='Diment Coin Diamond'
          width={600}
          height={360}
        />
      </Box>
      <Box w={'full'} maxW={{ base: '170px', sm: 'xs', md: '450px' }}>
        <Image src={bottomImg} alt='Diment Coin' width={600} height={495} />
      </Box>
    </Stack>
  );
};
