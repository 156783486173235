// HomeSimpleHero.tsx

import { innerLinks } from '@/utils/constants/inner-links';
import { Box, Flex, SimpleGrid, Stack, Divider } from '@chakra-ui/react';
import React from 'react';
import { colors } from '@/styles/theme/colors';
import { outerLinks } from '@/utils/constants/outer-links';
import { useFeatureData } from './constants/hero-features';
import HomeHeroFeatures from './HomeHeroFeatures';
import { HeroImage } from './HeroImage';
import { HeroActions } from './HeroActions';
import { HeroHeading } from './HeroHeading';
import Image from 'next/image';
import { TextDefault } from '@/components/Texts';
import { Trans } from 'next-i18next';
import Link from 'next/link';
import BaseButton from '@/components/BaseButton';

const HomeSimpleHero = () => {
  const features = useFeatureData();
  return (
    <Stack
      w='full'
      align={'center'}
      pt={{ base: 8, md: 32 }}
      pb={{ base: 8, md: 16 }}
      px={8}
      bg={colors.gradients.darkBlue}
    >
      <Flex
        pt={{ base: 16, md: 0 }}
        w='full'
        maxW='8xl'
        direction={{ base: 'column', md: 'row' }}
        justify='center'
        gap={{ base: 0, md: 24 }}
      >
        <HeroImage display={{ base: 'none', md: 'flex' }} />
        <Stack
          w={'full'}
          maxW={'3xl'}
          align='center'
          justify='center'
          pt={8}
          pb={10}
          spacing={10}
        >
          <Stack
            textAlign={{ base: 'center', md: 'left' }}
            w='full'
            spacing={{ base: 2, md: 12 }}
          >
            <Flex w={'full'} justify={{ base: 'center', md: 'left' }}>
              <HeroHeading colors={colors} />
            </Flex>
            <HeroImage display={{ base: 'flex', md: 'none' }} />
            <Flex w={'full'} justify={{ base: 'center', md: 'left' }}>
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                color={'white'}
                textAlign={'left'}
                w={'full'}
                maxW={{ base: 280, sm: 340, md: '2xl' }}
                gap={{ base: 4, md: 8 }}
              >
                {features.map((feature, index) => (
                  <HomeHeroFeatures
                    key={index}
                    imageSrc={feature.imageSrc}
                    altText={feature.altText}
                    textKey={feature.textKey}
                  />
                ))}
              </SimpleGrid>
            </Flex>
            <Flex
              w={'full'}
              justify={{ base: 'center', md: 'start' }}
              direction={{ base: 'column', md: 'row' }}
              align={'center'}
              gap={{ base: 4, md: 7 }}
              pt={{ base: 8, md: 0 }}
            >
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box
                  w={'full'}
                  maxW={180}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'translateY(-2px)',
                  }}
                  transitionDuration={'0.3s'}
                  transitionTimingFunction={'ease-in-out'}
                >
                  <Link href={'https://www.gifsrv.com/'} target='_blank'>
                    <Image
                      alt='gifs'
                      src={'/images/home/home-hero/gifs-logo-green.webp'}
                      width={951}
                      height={548}
                    />
                  </Link>
                </Box>
                <TextDefault
                  fontSize={13}
                  color={'white'}
                  textAlign={{ base: 'center', md: 'start' }}
                >
                  <Trans
                    ns='main'
                    i18nKey={'home.hero.gifs'}
                    components={{ 1: <strong /> }}
                  />
                </TextDefault>
              </Box>
              {/* <Divider orientation='vertical' />
              <Divider
                display={{ base: '', md: 'none' }}
                orientation='horizontal'
              />
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box
                  mt={{ base: 3, md: 0 }}
                  w={'full'}
                  maxW={180}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'translateY(-2px)',
                  }}
                  transitionDuration={'0.3s'}
                  transitionTimingFunction={'ease-in-out'}
                >
                  <Link
                    href={
                      'https://fxnewsgroup.com/forex-news/cryptocurrency/diment-obtains-initial-approval-from-dubais-vara/'
                    }
                    target='_blank'
                  >
                    <Image
                      alt='gifs'
                      src={'/images/home/home-hero/crypto-regulator.png'}
                      width={951}
                      height={548}
                    />
                  </Link>
                </Box>
                <TextDefault
                  fontSize={13}
                  color={'white'}
                  textAlign={{ base: 'center', md: 'start' }}
                >
                  Matching <strong>state of the art</strong> <br />
                  <strong>regulations.</strong>
                </TextDefault>
              </Box> */}
              <Divider orientation='vertical' />
              <Divider
                display={{ base: '', md: 'none' }}
                orientation='horizontal'
              />
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box
                  w={'full'}
                  maxW={180}
                  cursor={'pointer'}
                  _hover={{
                    transform: 'translateY(-2px)',
                  }}
                  transitionDuration={'0.3s'}
                  transitionTimingFunction={'ease-in-out'}
                >
                  <Link
                    href={'https://skynet.certik.com/projects/diment'}
                    target='_blank'
                  >
                    <Image
                      alt='gifs'
                      src={'/images/home/home-hero/certik.svg'}
                      width={951}
                      height={548}
                    />
                  </Link>
                </Box>
                <TextDefault
                  fontSize={13}
                  color={'white'}
                  mt={{ base: 0, md: 4 }}
                  mb={{ base: 0, md: '-.5rem' }}
                  textAlign={{ base: 'center', md: 'start' }}
                >
                  Completed a <strong>high-standard</strong> <br />{' '}
                  <strong>smart contract audit.</strong>
                </TextDefault>
              </Box>
            </Flex>
          </Stack>

          <HeroActions
            outerLinks={outerLinks}
            innerLinks={innerLinks}
            colors={colors}
          />
        </Stack>
      </Flex>
    </Stack>
  );
};

export default HomeSimpleHero;
