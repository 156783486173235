// ZoomableBackground.ts
import { Stack, BoxProps, Box } from '@chakra-ui/react';
import React, { useState } from 'react';

const ZoomableBackground = ({
  children,
  bgImage,
  ...rest
}: BoxProps & { bgImage: string }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Stack
      w='full'
      align='center'
      px={4}
      position='relative'
      overflow='hidden'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      <Box
        w='full'
        h='full'
        position='absolute'
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgImage={bgImage}
        bgSize='cover'
        bgRepeat='no-repeat'
        bgPosition='center'
        transition='transform 0.3s ease-in-out'
        transform={isHovered ? 'scale(1.1)' : 'scale(1)'}
        zIndex={0}
      />
      <Stack zIndex={1} w='full' align='center' textAlign='center'>
        {children}
      </Stack>
    </Stack>
  );
};

export default ZoomableBackground;
