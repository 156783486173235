// ProcessCard.tsx
import React from 'react';
import { Box, Flex, Icon, Stack, chakra } from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'next-i18next';
import { H6 } from '@/components/Headings';
import { TextLarge } from '@/components/Texts';
import { colors } from '@/styles/theme/colors';
import { useInView } from 'react-intersection-observer';

interface ProcessCardProps {
  title: string;
  description: string;
  isLast: boolean;
  isPreAudited?: boolean;
  isPreBacked?: boolean;
  icon: JSX.Element;
  index: number;
}

const AnimatedFlex = chakra(Flex, {
  baseStyle: {
    opacity: 0,
    transform: 'translateY(50px)',
    transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
  },
});

const ProcessCard: React.FC<ProcessCardProps> = ({
  title,
  description,
  isLast,
  isPreAudited,
  isPreBacked,
  icon,
  index,
}) => {
  const { t } = useTranslation('main');
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <AnimatedFlex
      w={'full'}
      ref={ref}
      opacity={inView ? 1 : 0}
      transform={inView ? 'translateY(0)' : 'translateY(50px)'}
      transitionDelay={{ base: `${0.2 * index}s`, md: `${0.5 * index}s` }}
      direction={{ base: 'column', md: 'row' }}
      align='center'
      justify='center'
      position='relative'
    >
      {/* Card Content */}
      <Stack
        px={{ base: 6, md: 8 }}
        py={8}
        bg={'rgba(100, 200, 255, 0.15)'}
        textAlign='center'
        spacing={8}
        align={'center'}
        justify={'center'}
        w={'full'}
        minH={'2xs'}
        mb={{ base: 4, md: 0 }}
      >
        <Stack align={'center'} gap={8} px={8}>
          <Box>{icon}</Box>
          <H6 fontSize={20} color={colors.diment.darkBlue}>
            {t(title)}
          </H6>
        </Stack>
      </Stack>

      {/* Pre-audited/Pre-backed Labels */}
      {isPreAudited && (
        <TextLarge
          color={colors.diment.darkBlue}
          fontWeight={700}
          position='absolute'
          bottom={'15px'}
          display={{ base: 'block', md: 'none' }}
        >
          {t('home.creation-process.pre-audited')}
        </TextLarge>
      )}

      {isPreBacked && (
        <TextLarge
          color={colors.diment.darkBlue}
          fontWeight={700}
          position='absolute'
          bottom={'15px'}
          display={{ base: 'block', md: 'none' }}
        >
          {t('home.creation-process.pre-backed')}
        </TextLarge>
      )}

      {/* Arrows */}
      {!isLast && (
        <Icon
          as={MdKeyboardArrowDown}
          fontSize='24px'
          color={colors.diment.lightBlue}
          display={{ base: 'block', md: 'none' }}
        />
      )}
    </AnimatedFlex>
  );
};

export default ProcessCard;
