// HomeHeroFeatures.tsx

import React from 'react';
import { Stack, Flex } from '@chakra-ui/react';
import { TextDefault } from '@/components/Texts';
import { Trans } from 'next-i18next';
import Image from 'next/image';

type HomeHeroFeaturesProps = {
  imageSrc: string;
  altText: string;
  textKey: string;
};

const HomeHeroFeatures: React.FC<HomeHeroFeaturesProps> = ({
  imageSrc,
  altText,
  textKey,
}) => {
  return (
    <Stack
      w={'full'}
      maxW={{ base: 'full', md: 200 }}
      align={{ base: 'center', md: 'start' }}
      justify={{ base: 'start', md: 'start' }}
      direction={{ base: 'row', md: 'column' }}
      spacing={4}
    >
      <Image alt={altText} src={imageSrc} width={57} height={57} />
      <Flex align={{ base: 'center', md: 'inherit' }} direction={'column'}>
        <TextDefault fontSize={13}>
          <Trans ns='main' i18nKey={textKey} components={{ 1: <strong /> }} />
        </TextDefault>
      </Flex>
    </Stack>
  );
};

export default HomeHeroFeatures;
