// OurNetwork.tsx

import { Box, Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { H2, H5 } from '@/components/Headings';
import { colors } from '@/styles/theme/colors';
import OurNetworkGrid from '@/components/OurNetwork/OurNetworkGrid';

const OurNetwork = () => {
  const { t } = useTranslation('main');
  return (
    <Box position='relative' w='full'>
      <Box
        position='absolute'
        top={0}
        right={0}
        bottom={0}
        left={0}
        bgImage='url(/images/home/network-bg.webp)'
        bgSize='cover'
        bgPosition='center'
        opacity='0.4'
      />
      <Stack
        position='relative'
        w='full'
        align='center'
        py={{ base: 16, md: 24 }}
        px={8}
        spacing={12}
      >
        <Stack w={'full'} maxW={'4xl'} textAlign='center' spacing={6}>
          <H5 color={colors.diment.blue}>
            {t('home.ournetwork.theDimentNetwork')}
          </H5>
          <H2 color={colors.diment.darkBlue}>{t('home.ournetwork.text')}</H2>
        </Stack>
        <OurNetworkGrid />
      </Stack>
    </Box>
  );
};

export default OurNetwork;
