import { useTranslation } from 'next-i18next';
import { Flex, SimpleGrid, Stack, Link, Box } from '@chakra-ui/react';
import { colors } from '@/styles/theme/colors';
import { H2 } from '@/components/Headings';
import { TextDefault } from '@/components/Texts';
import Image from 'next/image';
import { AppImageProps, StoreLinkProps } from '@/utils/constants/types';

const AppImage: React.FC<AppImageProps> = ({
  src,
  alt,
  width,
  height,
  display,
}) => (
  <Stack
    display={display}
    justify='end'
    align='center'
    maxW={{ base: 'sm', md: 400 }}
  >
    <Image src={src} alt={alt} width={width} height={height} />
  </Stack>
);

const StoreLink: React.FC<StoreLinkProps> = ({
  href,
  src,
  alt,
  width,
  height,
}) => (
  <Box maxW={150}>
    <Link href={href} target='_blank'>
      <Image src={src} alt={alt} width={width} height={height} />
    </Link>
  </Box>
);

const GetApp: React.FC = () => {
  const { t } = useTranslation('main');

  return (
    <Stack spacing={2} align='center' w='full'>
      <Flex
        bg={colors.gradients.lightBlue}
        direction={{ base: 'column', md: 'row' }}
        justify='center'
        align='end'
        gap={8}
        w='full'
        position='relative'
        overflow='hidden'
      >
        <Stack w='full' align='center' justify='center'>
          <SimpleGrid
            w='full'
            maxW='7xl'
            columns={{ base: 1, md: 2 }}
            justifyItems='center'
            gap={{ base: 4, md: 8 }}
            px={{ base: 8, xl: 0 }}
            py={{ base: 2, md: 8 }}
          >
            {/* Desktop Image */}
            <AppImage
              src='/images/assets/png-files/diment-app.png'
              alt='Diment App'
              width={866}
              height={1080}
              display={{ base: 'none', md: 'flex' }}
            />

            <Stack
              order={{ base: 1, md: 2 }}
              align={{ base: 'center', md: 'start' }}
              justify='center'
              w='full'
              maxW='lg'
              spacing={8}
              textAlign={{ base: 'center', lg: 'start' }}
              py={{ base: 8, md: 16 }}
            >
              <Stack
                w='full'
                spacing={4}
                align={{ base: 'center', lg: 'start' }}
              >
                <H2 color={colors.diment.darkBlue}>
                  {t('common.diment-app.heading')}
                </H2>
                <Flex
                  w='full'
                  maxW={{ base: 400, md: 'full' }}
                  justify={{ base: 'center', lg: 'start' }}
                >
                  <TextDefault fontWeight={500} color={colors.diment.darkBlue}>
                    {t('common.diment-app.desc')}
                  </TextDefault>
                </Flex>
              </Stack>

              {/* Mobile Image */}
              <AppImage
                src='/images/assets/png-files/diment-app.png'
                alt='Diment App'
                width={400}
                height={499}
                display={{ base: 'flex', md: 'none' }}
              />

              <Flex w='full' justify={{ base: 'center', lg: 'left' }} gap={2}>
                <StoreLink
                  href='#'
                  src='/images/home/googleplay_soon.png'
                  alt='Google Play'
                  width={256}
                  height={76}
                />
                <StoreLink
                  href='#'
                  src='/images/home/appstore_soon.png'
                  alt='App Store'
                  width={256}
                  height={75}
                />
              </Flex>
            </Stack>
          </SimpleGrid>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default GetApp;
