import React from 'react';
import { Flex, Circle, Box } from '@chakra-ui/react';
import { colors } from '@/styles/theme/colors';
import { TextDefault } from '@/components/Texts';
import { useTranslation } from 'next-i18next';
import EndLineArrow from './EndLineArrow';

type ProcessTimelineProps = {
  steps: string[];
};

const ProcessTimeline: React.FC<ProcessTimelineProps> = ({ steps }) => {
  const { t } = useTranslation('main');
  return (
    <Flex w={'full'} justify='center' position='relative' align='center'>
      <Flex
        height='2px'
        bg={colors.diment.lightBlue}
        w='full'
        position='absolute'
        _before={{
          content: '""',
          display: 'block',
          height: '1px',
          width: '10%',
          bg: 'white',
          borderTop: '2px dashed',
          borderColor: colors.diment.lightBlue,
          position: 'absolute',
          left: 0,
          top: '0px',
        }}
      >
        <Flex w='full' justify='space-around' position='absolute' top='-7px'>
          {steps.map((_, index) => (
            <Box
              key={index}
              left={`${(index / (steps.length - 1)) * 100}%`}
              transform='translateX(-50%)'
              textAlign='center' // Metni ortala
            >
              {/* Noktalar */}
              <Circle
                size='16px'
                bg={colors.diment.lightBlue}
                position='relative'
              />

              {/* 3. noktanın altındaki etiket */}
              {index === 2 && (
                <TextDefault
                  position='absolute'
                  bottom='-40px'
                  left={`${(index / (steps.length - 1)) * 100}%`}
                  transform='translateX(-50%)'
                  w='max-content'
                  color={colors.diment.darkBlue}
                  fontWeight={800}
                >
                  {t('home.creation-process.pre-audited')}
                </TextDefault>
              )}

              {/* 4. noktanın altındaki etiket */}
              {index === 3 && (
                <TextDefault
                  position='absolute'
                  bottom='-40px'
                  left={`${(index / (steps.length - 1)) * 100}%`}
                  transform='translateX(-50%)'
                  w='max-content'
                  color={colors.diment.darkBlue}
                  fontWeight={800}
                >
                  {t('home.creation-process.pre-backed')}
                </TextDefault>
              )}
            </Box>
          ))}
        </Flex>
      </Flex>
      <EndLineArrow />
    </Flex>
  );
};

export default ProcessTimeline;
