import { AiFillPlaySquare } from 'react-icons/ai';
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AspectRatio,
  Box,
  Flex,
} from '@chakra-ui/react';

import styles from './styles/hero-video.module.css';

import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import BaseButton from '@/components/BaseButton';
import { TextDefault } from '@/components/Texts';
const ReactPlayer = dynamic(() => import('react-player/lazy'), {
  ssr: false,
});

type VideoModalProps = {
  videoUrl: string;
  btnColor: string;
  textColor?: string;
};

const VideoModal: React.FC<VideoModalProps> = ({
  videoUrl,
  btnColor,
  textColor = 'white',
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('main');

  return (
    <>
      <Flex align={'center'} onClick={onOpen} cursor={'pointer'}>
        <BaseButton
          leftIcon={<AiFillPlaySquare />}
          bg={btnColor}
          color={textColor}
          onClick={onOpen}
          px={{ base: 4, sm: '38px' }}
          py={{ base: 4, sm: 7 }}
          fontSize={{ base: 'lg', sm: 'xl', lg: '2xl' }}
        >
          <TextDefault
            fontSize={{ base: 12, sm: 15, lg: 18 }}
            color={textColor}
          >
            {t('common.watch-video')}
          </TextDefault>
        </BaseButton>
      </Flex>

      {isOpen && (
        <Box className={styles['animated-video']}>
          <Modal isCentered onClose={onClose} size={'5xl'} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton
                zIndex={99}
                color={'black'}
                fontSize={'xl'}
                m={2}
              />
              <ModalBody p={0}>
                <AspectRatio ratio={16 / 9} w={'full'}>
                  <ReactPlayer
                    style={{
                      overflow: 'hidden',
                    }}
                    width={'100%'}
                    height={'auto'}
                    url={videoUrl}
                    controls
                    playing={isOpen}
                  />
                </AspectRatio>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default VideoModal;
