import { FormProvider, useForm } from 'react-hook-form';
import BaseButton from '../BaseButton';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  IconButton,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import Image from 'next/image';
import { BuyTokenForm } from './types';
import EnterAmountStep from './EnterAmountStep';
import SelectTypeStep from './SelectTypeStep';
import SelectCryptocurrencyStep from './SelectCryptocurrencyStep';
import SelectFiatStep from './SelectFiatStep';
import SelectMethodStep from './SelectMethodStep';

const LAST_STEP_INDEX = 3;

const BuyToken = ({
  buttonName,
  variant = 'outline',
  size = 'md',
  ...props
}: any) => {
  const imageWidth = useBreakpointValue({ base: 32, sm: 36, md: 48 });
  const [step, setStep] = useState(0);
  const { t } = useTranslation('main');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formMethods = useForm<BuyTokenForm>({
    resolver: yupResolver(
      yup.object({
        amount: yup.number().typeError('').min(0).required(),
        referralCode: yup.string().required(),
        type: yup.string().required(),

        cryptocurrency: yup.string().when('type', {
          is: 'cryptocurrency',
          then: (schema) => schema.required(),
        }),
        fiat: yup.string().when('type', {
          is: 'fiat',
          then: (schema) => schema.required(),
        }),

        method: yup.string().required(),
      }),
    ) as any,
  });

  const type = formMethods.watch('type');

  const goPrevStep = () => setStep((prev) => (prev !== 0 ? prev - 1 : prev));
  const goNextStep = () =>
    setStep((prev) => (prev !== LAST_STEP_INDEX ? prev + 1 : prev));

  const handleCancel = () => {
    setStep(0);
    onClose();
  };

  return (
    <>
      <BaseButton
        variant={variant}
        size={size}
        onClick={onOpen}
        fontWeight={700}
        borderRadius={'10px'}
        _hover={{
          transform: 'translateY(-2px)',
          shadow: 'xl',
        }}
        transitionDuration={'0.3s'}
        transitionTimingFunction={'ease-in-out'}
        {...props}
      >
        {buttonName || t('buy-token.buy')}
      </BaseButton>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={4}>
            <Flex align={'center'} justify={'center'}>
              <IconButton
                size={'sm'}
                colorScheme='gray'
                aria-label='back'
                icon={<ChevronLeftIcon />}
                onClick={goPrevStep}
                isDisabled={step === 0}
                hidden={step === 0}
              />
              <Flex gap={2} px={8} align={'center'}>
                <Image
                  src={`/images/diment-icon.svg`}
                  width={imageWidth}
                  height={imageWidth}
                  alt='logo'
                />
                <Text
                  textAlign={'center'}
                  fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }}
                  fontWeight={'medium'}
                >
                  {t('common.buy-token.buy-dd')}
                </Text>
              </Flex>
              <span />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={4} px={4}>
            <FormProvider {...formMethods}>
              {step === 0 ? (
                <EnterAmountStep
                  onCancel={handleCancel}
                  goNextStep={goNextStep}
                />
              ) : null}
              {step === 1 ? <SelectTypeStep goNextStep={goNextStep} /> : null}
              {step === 2 && type === 'cryptocurrency' ? (
                <SelectCryptocurrencyStep goNextStep={goNextStep} />
              ) : null}
              {step === 2 && type === 'fiat' ? (
                <SelectFiatStep goNextStep={goNextStep} />
              ) : null}
              {step === 3 ? <SelectMethodStep /> : null}
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BuyToken;
