import {
  Button,
  ButtonGroup,
  Stack,
  Text,
  Image,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BuyTokenForm, CommonStepProps } from './types';
import { useTranslation } from 'next-i18next';

const SelectTypeStep = ({ goNextStep }: CommonStepProps) => {
  const formMethods = useFormContext<BuyTokenForm>();
  const { t } = useTranslation('main');
  const type = formMethods.watch('type');

  return (
    <Stack spacing={4} py={4}>
      <Text textAlign={'center'} whiteSpace={'pre-line'} py={4}>
        {t('common.buy-token.type-description')}
      </Text>
      <ButtonGroup w={'full'}>
        <Button
          flex={1}
          size={'lg'}
          variant={type === 'cryptocurrency' ? 'outline' : 'outline'}
          onClick={() => {
            formMethods.setValue('type', 'cryptocurrency');
            goNextStep();
          }}
        >
          <Flex w='full' justify={'center'} gap={2} align={'center'}>
            <Image src={`/images/cryptocurrency.svg`} w={8} alt='' />
            {t('common.buy-token.type-crypto')}
          </Flex>
        </Button>
        <Button
          size={'lg'}
          flex={1}
          variant={type === 'fiat' ? 'outline' : 'outline'}
          onClick={() => {
            formMethods.setValue('type', 'fiat');
            goNextStep();
          }}
        >
          <Flex w='full' justify={'center'} gap={2} align={'center'}>
            <Image src={`/images/fiatcurrency.svg`} w={8} alt='' />
            {t('common.buy-token.type-fiat')}
          </Flex>
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default SelectTypeStep;
