// DynamicOurNetwork.tsx

import ChakraNextImage from '@/components/ChakraNextImage';

const DynamicOurNetworkImage = ({ member }: any) => {
  const imageSrc = `/images/networks/${member}-1.webp`;
  return (
    <ChakraNextImage
      src={imageSrc}
      alt={member}
      width={256}
      height={256}
      objectFit={'contain'}
    />
  );
};

export default DynamicOurNetworkImage;
