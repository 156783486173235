import { colors } from '@/styles/theme/colors';
import { Box } from '@chakra-ui/react';
import React from 'react';

export default function EndLineArrow() {
  return (
    <Box
      position='absolute'
      right='-8px'
      top='0%'
      transform='translateY(-3%)'
      width='100px'
      height='40px'
      bg='transparent'
      border={'2px'}
      borderColor={colors.diment.lightBlue}
      borderStart={'none'}
      borderRadius={'xl'}
      borderTopLeftRadius={'none'}
      borderBottomLeftRadius={'none'}
    >
      {/* Ok ikonu */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill={colors.diment.lightBlue}
        width='24px'
        height='24px'
        style={{
          transform: 'translateY(104%) translateX(-30%) rotate(180deg)',
        }}
      >
        <path d='M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z' />
      </svg>
    </Box>
  );
}
