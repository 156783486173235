// components/HeroText.tsx
import { Stack } from '@chakra-ui/react';
import { H1, H4 } from '@/components/Headings';
import { useTranslation } from 'next-i18next';

interface HeroHeadingProps {
  colors: any;
}

export const HeroHeading = ({ colors }: HeroHeadingProps) => {
  const { t } = useTranslation('main');

  return (
    <Stack w='full' maxW={{ base: 280, sm: 'full', xl: 700 }} spacing={1}>
      <H4 fontSize={{ base: 16, md: 25 }} color={colors.diment.lightBlue}>
        {t('home.hero.diment-dollar')}
      </H4>
      <H1
        w={'full'}
        maxW={{ base: 'full', xl: 'xl' }}
        fontSize={{ base: 30, md: 65 }}
        color='white'
      >
        {t('home.hero.heading')}
      </H1>
    </Stack>
  );
};
