// ProcessDisplay.tsx
import React from 'react';
import { Box, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import ProcessCard from './ProcessCard';
import {
  AuditSVG,
  DiamondSVG,
  DimentCoinSVG,
  StorageSVG,
  UsdSVG,
} from '@/assets/svgs/process';
import ProcessTimeline from './ProcessTimeline';
import EndLineArrowUp from './EndLineArrowUp';

const ProcessDisplay: React.FC = () => {
  const { t } = useTranslation('main');
  const cards = t('home.creation-process.cards', { returnObjects: true });

  const svgIcons: Record<string, JSX.Element> = {
    'diamond-purchase': <DiamondSVG />,
    'storage': <StorageSVG />,
    'diamond-reserve': <AuditSVG />,
    'reserve-usd': <UsdSVG />,
    'minting': <DimentCoinSVG />,
  };

  return (
    <Stack w={'full'} align={'center'} p={8}>
      <Stack gap={12}>
        <SimpleGrid
          w={'full'}
          maxW={'8xl'}
          columns={{ base: 1, md: 3, lg: 5 }}
          justifyItems={'center'}
          gap={4}
        >
          {Object.entries(cards).map(([key, card], index, array) => (
            <ProcessCard
              key={key}
              title={card.heading}
              description={card.desc}
              isLast={index === array.length - 1}
              isPreAudited={key === 'diamond-reserve'}
              isPreBacked={key === 'reserve-usd'}
              icon={svgIcons[key]}
              index={index}
            />
          ))}
        </SimpleGrid>
        <Flex w={'full'} display={{ base: 'none', lg: 'flex' }}>
          <ProcessTimeline steps={Object.keys(cards)} />
        </Flex>
      </Stack>
      <Box display={{ base: 'block', md: 'none' }}>
        <EndLineArrowUp />
      </Box>
    </Stack>
  );
};

export default ProcessDisplay;
